import React, { useEffect, useState } from "react";
import NavMobile from "../components/NavMobile";
import { CgMenuRight, CgClose } from "react-icons/cg";
import { navigation } from "../data";
import { Link } from "react-scroll";
const Header = () => {
  const [bg, setBg] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const closeMobileMenu = () => {
    setMobileNav(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      return window.scrollY > 50 ? setBg(true) : setBg(false);
    });
  });
  return (
    <header
      className={`${
        bg ? "bg-primary py-4 lg:py-6" : "bg-none"
      } fixed left-0 w-full py-8 z-10 transition-all duration-200`}
    >
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <a href="/">
            <h1 className="text-white text-2xl">Tapetar Branko</h1>
          </a>
          <div
            onClick={() => {
              setMobileNav(!mobileNav);
            }}
            className="text-2xl text-white md:hidden lg:text-3xl cursor-pointer"
          >
            {mobileNav ? <CgClose /> : <CgMenuRight />}
          </div>
          <nav className="hidden md:flex">
            <ul className="md:flex md:gap-x-12">
              {navigation.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      activeClass="active"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="capitalize text-white hover:border-b transition-all"
                      to={item.href}
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div
            className={`${
              mobileNav ? "left-0" : "-left-full"
            } md:hidden fixed bottom-0 w-full max-w-xs h-screen transition-all`}
          >
            <NavMobile closeMobileMenu={closeMobileMenu} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
