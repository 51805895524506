import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../slider.css";
import { Pagination, Navigation } from "swiper/modules";
import { products } from "../data";

const ProductSlider = () => {
  const { pages } = products;
  return (
    <Swiper
      modules={[Pagination, Navigation]}
      pagination={{ clickable: true }}
      navigation={true}
      className="productSlider min-h-[1000px]"
    >
      {pages.map((page, index) => {
        return (
          <SwiperSlide key={index}>
            <div className="grid grid-cols-2 gap-x-5 md:grid-cols-3 lg:grid-cols-4 lg:gap-[30px]">
              {page.productList.map((product, index) => {
                const { image, name } = product;
                return (
                  <div
                    className="w-full max-w-[290px] mb-3 h-[150px] lg:h-[280px] text-left"
                    key={index}
                  >
                    <div className="border rounded-[18px] w-full max-w-[285px] h-full max-h-[153px] flex items-center justify-center mb-[15px] relative transition">
                      <img src={image} alt={name} />
                    </div>
                  </div>
                );
              })}
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ProductSlider;
