import React from "react";
import { Link } from "react-scroll";

import { navigation } from "../data";

const NavMobile = ({ closeMobileMenu }) => {
  return (
    <div className="bg-white h-full w-full shadow-2xl">
      <ul className="text-center h-full flex flex-col items-center justify-center gap-y-6">
        {navigation.map((item, index) => {
          return (
            <li key={index} onClick={closeMobileMenu}>
              <Link
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
                className="text-xl capitalize font-medium"
                to={item.href}
                onClick={closeMobileMenu}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavMobile;
