import React from "react";
import { CgPhone } from "react-icons/cg";
import { cta } from "../data";

const Cta = () => {
  const { title, subtitle, buttonText } = cta;
  return (
    <section
      className="section bg-newsletter bg-cover bg-center bg-no-repeat min-h-[490px]"
      id="#kontakt"
    >
      <div className="container mx-auto flex justify-center lg:justify-end">
        <div className="w-full max-w-[588px] text-center text-white lg:text-left">
          <h2 className="text-4xl font-semibold max-w-[350px] mx-auto lg:mx-0 mb-5 leading-normal">
            {title}
          </h2>
          <p className="text-xl font-light mb-10">{subtitle}</p>
          <div className="flex flex-col lg:flex-row gap-[22px]">
            <a
              href="tel:+381653238789"
              className="bg-primary h-[60px] flex items-center justify-center hover:bg-slate-900 px-7 rounded-lg font-medium transition text-xl"
            >
              {buttonText}
              <CgPhone className="ms-2" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
