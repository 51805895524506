import React from "react";

// import components
import Hero from "./components/Hero";
import Features from "./components/Features";
import NewItems from "./components/NewItems";
import FeaturesSecond from "./components/FeaturesSecond";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Cta from "./components/Cta";
import Products from "./components/Products";
import Testimonial from "./components/Testimonial";

const App = () => {
  return (
    <div className="w-full mx-auto bg-white">
      <Header />
      <Hero />
      <Features />
      <NewItems />
      <FeaturesSecond />
      <Products />
      <Testimonial />
      <Cta />
      <Footer />
    </div>
  );
};

export default App;
