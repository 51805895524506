// import icons
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoMdAddCircle,
  IoIosCheckmarkCircle,
  IoIosArrowRoundForward,
} from "react-icons/io";

// import images
import Features1Img from "./assets/img/features-1.png";
import Features2Img from "./assets/img/features-2.png";
import ChairImg from "./assets/img/products/13.jpg";
import BedImg from "./assets/img/products/16.jpg";
import CupboardImg2 from "./assets/img/products/20.jpg";
import LightingImg2 from "./assets/img/products/18.jpg";
import CupboardImg from "./assets/img/products/1.jpg";
import LightingImg from "./assets/img/products/2.jpg";
import Product1Img from "./assets/img/products/3.jpg";
import Product2Img from "./assets/img/products/4.jpg";
import Product3Img from "./assets/img/products/5.jpg";
import Product4Img from "./assets/img/products/6.jpg";
import Product5Img from "./assets/img/products/7.jpg";
import Product6Img from "./assets/img/products/8.jpg";
import Product7Img from "./assets/img/products/9.jpg";
import Product8Img from "./assets/img/products/10.jpg";
import Product9Img from "./assets/img/products/11.jpg";
import Product10Img from "./assets/img/products/12.jpg";
import Product11Img from "./assets/img/products/1.jpg";
import Product12Img from "./assets/img/products/2.jpg";
import Product13Img from "./assets/img/products/13.jpg";
import Product14Img from "./assets/img/products/14.jpg";
import Product15Img from "./assets/img/products/15.jpg";
import Product16Img from "./assets/img/products/16.jpg";
import Product17Img from "./assets/img/products/17.jpg";
import Product18Img from "./assets/img/products/18.jpg";
import Product19Img from "./assets/img/products/19.jpg";
import Product20Img from "./assets/img/products/20.jpg";
import TestimonialImg from "./assets/img/testimonial.png";
import Avatar1Img from "./assets/img/avatar-1.png";
import Avatar2Img from "./assets/img/avatar-2.png";
import Avatar3Img from "./assets/img/avatar-3.png";

export const navigation = [
  {
    name: "Početna",
    href: "#početna",
  },
  {
    name: "O nama",
    href: "#o-nama",
  },
  {
    name: "Galerija",
    href: "#galerija",
  },
  {
    name: "Kontakt",
    href: "#kontakt",
  },
];

export const hero = {
  title: "Personalizovani Nameštaj u Loznici",
  subtitle: "Vaša Ideja, Naše Majstorstvo!",
  buttonText: "Saznaj više",
};

export const stats = [
  {
    value: "20+",
    text: "Godina iskustva",
  },
  {
    value: "3",
    text: "Člana našeg tima",
  },
  {
    value: "1k+",
    text: "Napravljeno po meri",
  },
  {
    value: "460+",
    text: "Tapaciranog nameštaja",
  },
];

export const features = {
  image: <Features1Img />,
  title: "Unapređujemo estetiku vašeg doma",
  subtitle: "Višegodišnje iskustvo u tapaciranju i izradi nameštaja po meri!",
  buttonText: "Show Now",
  items: [
    {
      icon: <IoIosCheckmarkCircle />,
      title: "Tapaciranje nameštaja",
      subtitle: "Reparacija starog nameštaja.",
    },
    {
      icon: <IoIosCheckmarkCircle />,
      title: "Izrada nameštaja po meri",
      subtitle: "Pravimo nameštaj po Vašim merama i željama.",
    },
  ],
  feature2: {
    image: <Features2Img />,
    title: "Najbolji nameštaj po Vašem ukusu.",
    subtitle:
      "Nudimo vam najbolji nameštaj po vašem ukusu u našoj tapetarskoj radnji u Loznici. Naš tim stručnjaka posvećen je tome da vaše ideje pretvori u stvarnost, pružajući vam personalizovana rešenja koja savršeno odgovaraju vašim potrebama i estetskim preferencijama. Bez obzira da li tražite moderni komad nameštaja koji će osvežiti vaš prostor ili klasičan komad koji će doneti eleganciju, mi smo tu da vam pružimo vrhunski kvalitet i nezaboravno iskustvo.",
  },
};

export const newInStore = {
  title: "Istaknuti radovi",
  subtitle: "Pregledajte neke od naših istaknutih radova",
  icon: <IoIosArrowRoundForward />,
  products: [
    {
      name: "chair",
      image: <ChairImg />,
    },
    {
      name: "bed",
      image: <BedImg />,
    },
    {
      name: "cupboard",
      image: <CupboardImg />,
    },
    {
      name: "lighting",
      image: <LightingImg />,
    },
    {
      name: "cupboard",
      image: <CupboardImg2 />,
    },
    {
      name: "lighting",
      image: <LightingImg2 />,
    },
  ],
};

export const products = {
  title: "Naši radovi",
  subtitle: "Pregledajte neke od naših novijih radova.",
  pages: [
    {
      productList: [
        {
          image: Product1Img,
          icon: <IoMdAddCircle />,
          name: "Francuski ležaj",
        },
        {
          image: Product2Img,
          icon: <IoMdAddCircle />,
          name: "Dvosed",
        },
        {
          image: Product3Img,
          icon: <IoMdAddCircle />,
          name: "Trosed",
        },
        {
          image: Product4Img,
          icon: <IoMdAddCircle />,
          name: "Ugaona garnitura",
        },
        {
          image: Product5Img,
          icon: <IoMdAddCircle />,
          name: "Tabure",
        },
        {
          image: Product6Img,
          icon: <IoMdAddCircle />,
          name: "Stilski dvosed",
        },
        {
          image: Product7Img,
          icon: <IoMdAddCircle />,
          name: "Trosed",
        },
        {
          image: Product8Img,
          icon: <IoMdAddCircle />,
          name: "Fotelja",
        },
        {
          image: Product9Img,
          icon: <IoMdAddCircle />,
          name: "Set nameštaja za dnevni boravak",
        },
        {
          image: Product10Img,
          icon: <IoMdAddCircle />,
          name: "Ugaona garnitura",
        },
      ],
    },
    {
      productList: [
        {
          image: Product11Img,
          icon: <IoMdAddCircle />,
          name: "Stilski nameštaj",
        },
        {
          image: Product12Img,
          icon: <IoMdAddCircle />,
          name: "Ugaona garnitura",
        },
        {
          image: Product13Img,
          icon: <IoMdAddCircle />,
          name: "Papper Cupboard",
        },
        {
          image: Product14Img,
          icon: <IoMdAddCircle />,
          name: "Ole Gundorse Spring",
        },
        {
          image: Product15Img,
          icon: <IoMdAddCircle />,
          name: "Treos Seroes 911",
        },
        {
          image: Product16Img,
          icon: <IoMdAddCircle />,
          name: "Multi bilderman slibber",
        },
        {
          image: Product17Img,
          icon: <IoMdAddCircle />,
          name: "XORA corner desk",
        },
        {
          image: Product18Img,
          icon: <IoMdAddCircle />,
          name: "Black Forest Series Wood",
        },
        {
          image: Product19Img,
          icon: <IoMdAddCircle />,
          name: "Papper Cupboard",
        },
        {
          image: Product20Img,
          icon: <IoMdAddCircle />,
          name: "Ole Gundorse Spring",
        },
      ],
    },
  ],
};

export const testimonial = {
  title: "Šta kažu o nama?",
  image: <TestimonialImg />,
  persons: [
    {
      avatar: <Avatar1Img />,
      name: "Marko K.",
      message:
        "“Izuzetno sam zadovoljan uslugom tapetarske radnje Branko u Loznici! Profesionalni pristup, visokokvalitetni materijali i precizna izrada nameštaja po meri su samo neki od razloga zašto im dajem najviše ocene.”",
    },
    {
      avatar: <Avatar2Img />,
      name: "Slađana K.",
      message:
        "“Oduševljena sam kako je tapetarske radnje Branko u Loznici uspela da naš dom transformiše iz običnog u elegantno i udobno mesto. Njihova pažnja prema detaljima i posvećenost kvalitetu su zaista impresivni.”",
    },
    {
      avatar: <Avatar3Img />,
      name: "Petra M.",
      message:
        "“Ne mogu dovoljno da istaknem koliko sam zadovoljna uslugom tapetarske radnje Branko u Loznici. Naš nameštaj po meri je upravo onakav kakav smo želeli, a celokupno iskustvo saradnje sa njima je bilo izvanredno.”",
    },
  ],
};

export const cta = {
  title: "Unikatni Nameštaj po Meri u Loznici - Oživite Vaš Dom!",
  subtitle: "Vaša Vizija, Naša Kreacija.",
  buttonText: "Zakaži konsultacije",
};

export const footer = {
  social: [
    {
      icon: <IoLogoInstagram />,
      href: "https://www.instagram.com/branka_bojic/",
    },
    {
      icon: <IoLogoFacebook />,
      href: "https://www.facebook.com/p/Tapetarska-radnja-Branko-Loznica-100063455561877/",
    },
  ],
  copyright: "Tapetar Branko 2024 - Sva prava zadržana.",
};
