import React from "react";
import NewItemsSlider from "./NewItemsSlider";

//import data
import { newInStore } from "../data";

const NewItems = () => {
  //destructure
  const { title, subtitle } = newInStore;
  return (
    <section className="section overflow-hidden">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <div className="flex md:flex-col items-baseline gap-x-6 mb-6 lg:mb-0">
            <h2 className="title max-w-[245px] lg:mt-[30px]">{title}</h2>
            <p className="max-w-[245px] lg:mb-12">{subtitle}</p>
          </div>
          <div className="lg:max-w-[800px] xl:max-w-[990px] lg:absolute lg:right-[300px]">
            <NewItemsSlider />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewItems;
